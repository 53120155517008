import * as React from 'react'
import * as css from './document-upload.module.css'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import LoadingLayout from 'layouts/Loading'
import HeroWithBg from 'components/common/HeroWithBg'
import Richtext from 'components/page-blocks/Richtext'

const App = () => {
  const { sanityDocumentUploadPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.meta}>
      <HeroWithBg {...pg.hero} />
      <Richtext {...pg} className="container-sm padding richtext" />

      <section className="container padding richtext">
        <div className="container-txt">
          <iframe
            className={css.iframe}
            src="https://cpsinsurance.citrixdata.com/remoteupload/remoteuploadform.aspx?id=6608708b-5350-45dc-a7c6-a3effd0ceb24"
            loading="lazy"
            title={pg.hero.title}
          ></iframe>
        </div>
      </section>
    </DefaultLayout>
  )
}

const DocUploadPg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default DocUploadPg

const query = graphql`query DocumentUploadPg {
  sanityDocumentUploadPg {
    hero { ...hero }
    _rawDescription
    seo {
      title
      description
    }
  }
}`
